import gravitycoliving from './gravitycoliving';
import neybor from './neybor';
import vitanovae from './vitanovae';

const { NODE_ENV } = process.env;

const getClientConfig = (cl) => {
  const deltas = { gravitycoliving, neybor, vitanovae }[cl];

  return NODE_ENV === 'development' ? deltas.test : deltas.prod;
}

export { getClientConfig };
