import { ViewingType } from "../features/enquiry/enquirySlice";

const chunk = <T>(arr: T[], chunkSize: number): T[][] => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk: T[] = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }

  return res;
};

type OfferCardBuildingMap = {
  [key: string]: string;
};

type Buildings = {
  [key: string]: OfferCardBuildingMap;
};

const buildingMap: Buildings = {
  gravity: {
    "west court":
      "https://www.gravitycoliving.com/apply/register/gravity-london-west-court",
    "finsbury park":
      "https://www.gravitycoliving.com/apply/register/gravity-london-finsbury-park",
    "camden lock":
      "https://www.gravitycoliving.com/apply/register/gravity-london-camden-lock",
    "camden town":
      "https://www.gravitycoliving.com/apply/register/gravity-london-camden-town",
    "royal heights":
      "https://www.gravitycoliving.com/apply/register/gravity-reading",
  },
  gravitycoliving: {
    "west court":
      "https://www.gravitycoliving.com/apply/register/gravity-london-west-court",
    "finsbury park":
      "https://www.gravitycoliving.com/apply/register/gravity-london-finsbury-park",
    "camden lock":
      "https://www.gravitycoliving.com/apply/register/gravity-london-camden-lock",
    "camden town":
      "https://www.gravitycoliving.com/apply/register/gravity-london-camden-town",
    "royal heights":
      "https://www.gravitycoliving.com/apply/register/gravity-reading",
  },
  neybor: {
    "Flagey 21": "https://www.neybor.co/request/want-to-live-here",
    "Fernand 12": "https://www.neybor.co/request/want-to-live-here",
    "Fernand 3": "https://www.neybor.co/request/want-to-live-here",
    "Louise 65": "https://www.neybor.co/request/want-to-live-here",
    "Flagey 33": "https://www.neybor.co/request/want-to-live-here",
    "Louise 13": "https://www.neybor.co/request/want-to-live-here",
    "Parvis 4": "https://www.neybor.co/request/want-to-live-here",
    "Artan 112": "https://www.neybor.co/request/want-to-live-here",
    "Colignon 39": "https://www.neybor.co/request/want-to-live-here",
    "Duden 3": "https://www.neybor.co/request/want-to-live-here",
    "Duden 145": "https://www.neybor.co/request/want-to-live-here",
    "Royal 46": "https://www.neybor.co/request/want-to-live-here",
    "Royal 22": "https://www.neybor.co/request/want-to-live-here",
    "Sainte-Catherine 15": "https://www.neybor.co/request/want-to-live-here",
    "Botanique 31": "https://www.neybor.co/request/want-to-live-here",
    "Botanique 42": "https://www.neybor.co/request/want-to-live-here",
    "Chatelain 63": "https://www.neybor.co/request/want-to-live-here",
  },
  vitanovae: {}
};

type CalendlyUrls = {
  [key: string]: string;
};

type Calendly = {
  [key: string]: CalendlyUrls;
};

const calendlies: Calendly = {
  gravity: {
    "west court": "https://calendly.com/gravity-coliving/viewing-west-court",
    default: "https://calendly.com/gravity-coliving/viewing-west-court",
    "camden lock": "https://calendly.com/gravity-coliving/viewing?location=2",
    "camden town": "https://calendly.com/gravity-coliving/viewing?location=1",
    "finsbury park": "https://calendly.com/gravity-coliving/viewing?location=3",
    "royal heights":
      "https://calendly.com/gravity-coliving/viewing-gravity-royal-heights",
  },
  gravitycoliving: {
    "west court": "https://calendly.com/gravity-coliving/viewing-west-court",
    default: "https://calendly.com/gravity-coliving/viewing-west-court",
    "camden lock": "https://calendly.com/gravity-coliving/viewing?location=2",
    "camden town": "https://calendly.com/gravity-coliving/viewing?location=1",
    "finsbury park": "https://calendly.com/gravity-coliving/viewing?location=3",
    "royal heights":
      "https://calendly.com/gravity-coliving/viewing-gravity-royal-heights",
  },
  neybor: {
    "flagey 21": "https://calendly.com/neybor-coliving",
    "fernand 12": "https://calendly.com/neybor-coliving",
    "fernand 3": "https://calendly.com/neybor-coliving",
    "louise 65": "https://calendly.com/neybor-coliving",
    "flagey 33": "https://calendly.com/neybor-coliving",
    "louise 13": "https://calendly.com/neybor-coliving",
    "parvis 4": "https://calendly.com/neybor-coliving",
    "artan 112": "https://calendly.com/neybor-coliving",
    "colignon 39": "https://calendly.com/neybor-coliving",
    "duden 3": "https://calendly.com/neybor-coliving",
    "duden 145": "https://calendly.com/neybor-coliving",
    "royal 46": "https://calendly.com/neybor-coliving",
    "royal 22": "https://calendly.com/neybor-coliving",
    "sainte-catherine 15": "https://calendly.com/neybor-coliving",
    "botanique 31": "https://calendly.com/neybor-coliving",
    "botanique 42": "https://calendly.com/neybor-coliving",
    "chatelain 63": "https://calendly.com/neybor-coliving",
    "neybor demo": "https://calendly.com/neybor-coliving",
    default: "https://calendly.com/neybor-coliving",
  },
  vitanovae: {
    default: "https://calendly.com/vitanovae7/30min",
    fr: "https://calendly.com/vitanovae7/30min",
    en: "https://calendly.com/vitanovae7/virtual-viewing",
  },
  default: {
    default: "https://calendly.com/dgalitsyn/30min",
  },
};

const neyborPaths: Record<ViewingType, string> = {
  Call: "/book-a-call-to-validate-your-application",
  "Video Call": "/introduction-call",
  "In person": "",
};

type Coordinates = {
  lat: number;
  lon: number;
};

const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

function distanceInKmBetweenEarthCoordinates(
  { lat: lat1, lon: lon1 }: Coordinates,
  { lat: lat2, lon: lon2 }: Coordinates
): number {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusKm * c;
}

export {
  chunk,
  buildingMap,
  calendlies,
  distanceInKmBetweenEarthCoordinates,
  neyborPaths,
};
