import FlagIcon from "../FlagIcon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {useState} from "react";
import LanguageOption from "../LanguageOption";
import {setSelectedLanguage, Translation} from "../../../features/common/commonSlice";
import {useTranslation} from "react-i18next";

const Language = () => {
	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(false);

	const selectedLanguage = useAppSelector((state: RootState) => {
		const { common } = state;
		return common.selectedLanguage;
	});

	const availableLanguages = useAppSelector((state: RootState) => {
		const { common } = state;
		return common.availableLanguages;
	});

	const client = useAppSelector((state: RootState) => {
		const { enquiry } = state;
		const { client } = enquiry;
		return client;
	});

	const { i18n } = useTranslation(client as string);

	const handleOpen = () => {
		setIsOpen(!isOpen);
	}

	const selectLanguage = (lang: Translation) => {
		setIsOpen(false);
		dispatch(setSelectedLanguage(lang.lang));
		i18n.changeLanguage(lang.lang);
	}

	if (availableLanguages && availableLanguages.length < 2) {
		return null;
	}

	const currentLanguage = availableLanguages.find((lang) => lang.lang === selectedLanguage);

	if (!currentLanguage) {
		return null;
	}

	return <>
			<button
				onClick={handleOpen}
				className="z-600 absolute top-2.5 left-2.5 w-auto h-6 inline-flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-1.5 py-0.5 bg-white text-xs text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
				<FlagIcon country={currentLanguage.flagIcon} /> {currentLanguage.name}
			</button>
			{isOpen && <LanguageOption languages={availableLanguages} selectLanguage={selectLanguage} />}
		</>;
};

export default Language;
