import en from './locales/en';
import fr from './locales/fr';
import {calendlies} from "../service/utils";

const steps = [
  {
    name: 'Where?',
    internalName: 'Where',
    route: '/',
    title: 'Where would you like to live?',
    stepIndex: 0,
  },
  {
    name: 'When?',
    internalName: 'When',
    route: '/when',
    title: 'When would you like to move?',
    stepIndex: 1,
  },
  {
    name: 'When?',
    internalName: 'HowLong',
    route: '/how-long',
    title: 'How long are you staying?',
    stepIndex: 1,
  },
  {
    name: 'Who?',
    internalName: 'Who',
    route: '/who',
    title: "Who's moving in?",
    stepIndex: 2,
  },
  {
    name: 'Budget?',
    internalName: 'Budget',
    route: '/budget',
    title: 'What’s your monthly budget?',
    stepIndex: 3,
  },
  {
    name: 'Units',
    internalName: 'Offers',
    route: '/offers',
    title: 'This is what we have available for you...',
    stepIndex: 4,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Viewing',
    route: '/viewing',
    title: 'How would you like to do the viewing?',
    stepIndex: 5,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Done',
    route: '/done',
    title: 'Done',
    stepIndex: 5,
  },
];

const budgetScale = {
  initialMin: 0,
  initialMax: 2000,
  min: 0,
  max: 5000,
  step: 200,
};

const longStayBookingTypeIdentifier = '642a7d59-9edb-40d3-9d30-9d24b9c02781';

const currencySymbol = '€';

const availabilityStep = true;

const namespace = 'vitanovae';

const english = en();
const french = fr();

const translations = [
  { lang: 'en', resource: english, flagIcon: 'gb', name: 'English' },
  { lang: 'fr', resource: french, flagIcon: 'fr', name: 'Français' },
];

english.pages.Budget.blurb = '';
french.pages.Budget.blurb = '';

english.pages.HowLong.months = {
  0: { text: '1-3 months', months: 1 },
  1: { text: '3-12 months', months: 3 },
  2: { text: '12+ months', months: 12 },
};

french.pages.HowLong.months = {
  0: { text: '1-3 mois', months: 1 },
  1: { text: '3-12 mois', months: 3 },
  2: { text: '12+ mois ou plus', months: 12 },
}

const howLongMonths = english.pages.HowLong.months;

const availabilityFilter = (availabilities) => {
  // rate plans: "Long séjours à 0% TVA" and "Long séjours à 10% TVA"
  const ids = ["6", "8"];
  return availabilities.filter((availability) => {
    const { rateAvailability } = availability;
    return ids.includes(rateAvailability.ratePlanIdentifier);
  });
};

const calendlyLinkFilter = (buildingName, language = 'en') => {
  const links = calendlies[namespace];
  // building name doesnt matter here
  const link = links[language];
  return link ?? links.default;
}

const test = {
  petSection: false,
  steps,
  budgetScale,
  availabilityStep,
  namespace,
  translations,
  currencySymbol,
  longStayBookingTypeIdentifier,
  howLongMonths,
  availabilityFilter,
  calendlyLinkFilter,
};

const prod = {
  petSection: false,
  steps,
  budgetScale,
  availabilityStep,
  namespace,
  translations,
  currencySymbol,
  longStayBookingTypeIdentifier,
  howLongMonths,
  availabilityFilter,
  calendlyLinkFilter,
};

const deltas = {
  test,
  prod,
};

export default deltas;
