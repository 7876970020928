import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { XIcon } from '@heroicons/react/outline';
import { setImages } from '../../../features/enquiry/enquirySlice';
import { Carousel } from 'react-responsive-carousel';
import {RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";

const Modal = () => {
  const dispatch = useAppDispatch();
  const images = useAppSelector((state) => {
    const { enquiry } = state;
    return enquiry.images;
  });
  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client ?? '';
  });
  const { t } = useTranslation(client as string);
  const close = () => {
    dispatch(setImages([]));
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black/60 flex justify-center items-center">
      <button
        className="absolute top-5 right-5 w-10 text-center text-slate-200 hover:text-slate-100"
        onClick={close}
        type="button"
      >
        <XIcon viewBox="0 0 24 24" />
        <span className="text-sm">
          {t('common.buttons.close')}
        </span>
      </button>

      <Carousel
        className="h-3/4 w-full"
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows={true}
      >
        {images.map((image, index) => (
          <div key={index} className="h-full w-full">
            <img
              src={image.url}
              alt="apartment image"
              className="h-full object-contain"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Modal;
