import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';

import {useAppDispatch, useAppSelector, useStep} from '../../../app/hooks';
import { setFrom } from '../../../features/enquiry/enquirySlice';

import AppLayout from '../../ui/AppLayout';
import Heading from '../../ui/Heading';
import {RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";

const WhenPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const { t } = useTranslation(client ?? 'translation');

  const currentStep = useStep('/when');
  const now = new Date();

  const handleChangeFrom = (from: Date) => {
    const timeStamp = from.getTime() / 1000;
    dispatch(setFrom(timeStamp));
    navigate('/how-long');
  };

  return (
    <AppLayout image="when.jpg" step={currentStep}>
      <Heading className="second-font mb-10">{t('pages.When.heading')}</Heading>
      <div className="flex flex-col items-center justify-center w-full">
        <Calendar
          onChange={(value: any) => handleChangeFrom(value)}
          minDate={now}
        />
      </div>
    </AppLayout>
  );
};

export default WhenPage;
