import {FC} from "react";

interface FlagIconProps {
	country: string;
}
const FlagIcon: FC<FlagIconProps> = ({country}) => {
	return (
		<span
			className={`fi fis inline-block mr-2 fi-${country}`}
		/>
	);
}

export default FlagIcon;
