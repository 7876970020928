import {Translation} from "../../../features/common/commonSlice";
import {FC} from "react";
import FlagIcon from "../FlagIcon";

interface LanguageOptionProps {
	languages: Translation[];
	// eslint-disable-next-line no-unused-vars
	selectLanguage: (lang: any) => void;
}
const LanguageOption: FC<LanguageOptionProps> = ({languages = [], selectLanguage = () => {}}) => {
	if (languages.length === 0) {
		return null;
	}

	return <div className="origin-top-left absolute z-600 left-2.5 top-9 bg-white border shadow-sm border-gray-300 rounded-sm">
		{languages.map((lang) => {
			return <button
				onClick={() => selectLanguage(lang)}
				key={lang.lang}
				className="flex align-text-top justify-items-start w-full px-4 py-2 text-xs hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
				<FlagIcon country={lang.flagIcon}/> {lang.name}
			</button>
		})}
	</div>
}

export default LanguageOption;
