import { FC, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setImages } from '../../../features/enquiry/enquirySlice';
import { addWeeks, fromUnixTime, isAfter, format } from 'date-fns';
import OfferCardButton from '../OfferCardButton';
import OfferImage from '../OfferImage/OfferImage';
import OfferCardLinkButton from '../OfferCardLinkButton';
import { OfferImageType } from '../../../service/types';
import { RootState } from '../../../app/store';
import { useTranslation } from 'react-i18next';

interface OfferCardProps {
  onChoose: (offerId: string | number, rateIf: string) => void; // eslint-disable-line
  offer: any;
}

const OfferCard: FC<OfferCardProps> = ({ onChoose, offer }) => {
  const dispatch = useAppDispatch();
  // eslint-disable-line
  const [pictureIndex, setPictureIndex] = useState(0); // eslint-disable-line
  // get the from date from redux
  const from = useAppSelector((state) => {
    const { enquiry } = state;
    return enquiry.from;
  });

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    const { client } = enquiry;
    return client;
  });

  const selectedLanguage = useAppSelector((state: RootState) => {
    const { common } = state;
    return common.selectedLanguage;
  });

  const { t } = useTranslation(client as string);

  // usememo to calculate from date plus 6 weeks
  const reachedLimit = useMemo((): boolean => {
    if (from === null || from === undefined) {
      return false;
    }
    // @ts-ignore
    const fromUnitDate = fromUnixTime(from);
    const finalDate = addWeeks(new Date(), 6);

    return isAfter(fromUnitDate, finalDate);
  }, [from]);

  const availableFrom = useMemo((): string | null => {
    if (offer.rateAvailability.interval?.dateFrom) {
      return format(
        new Date(offer.rateAvailability.interval?.dateFrom),
        'dd/MM/yyyy'
      );
    }

    return null;
  }, [offer]);

  let images = [];
  if (
    offer.mainImage !== undefined &&
    offer.mainImage !== null &&
    offer.mainImage.url !== ''
  ) {
    images.push(offer.mainImage);
  }
  if (offer.images.length > 0) {
    images.push(offer.images);
  }
  images = images.flat(10);
  const callback = (id: string, rateId: string) => {
    onChoose(id, rateId);
  };
  const setImagesAsModal = (image: OfferImageType) => {
    const images = [offer.mainImage, ...offer.images];

    dispatch(
      setImages(
        images.sort((a, b) => {
          if (image.url === a.url) {
            return -1;
          } else if (image.url === b.url) {
            return 1;
          }

          return 0;
        })
      )
    );
  };

  const propertyTypeDescription = (offer: any, language: any) => {
    const l = language.toUpperCase();
    const { propertyTypeDescription, propertyTypeDescriptionTranslations } = offer;
    if (propertyTypeDescriptionTranslations === undefined || propertyTypeDescriptionTranslations === null || propertyTypeDescriptionTranslations.length === 0) {
      return propertyTypeDescription;
    }
    let desc = propertyTypeDescription;
    const targetDesc = propertyTypeDescriptionTranslations.find((tar: any) => {
      return tar[l] !== undefined;
    });
    if (targetDesc !== undefined) {
      desc = targetDesc[l];
    }
    return desc;
  };

  const { buildingName } = offer;

  // @ts-ignore
  return (
    <div className="border-gray-300 border flex flex-col justify-content-between rounded-md max-w-sm m-2 p-1">
      <div className="h-48 flex items-center relative">
        {images.length > 1 ? (
          <svg
            onClick={() => {
              setPictureIndex(
                pictureIndex === 0 ? images.length - 1 : pictureIndex - 1
              );
            }}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        ) : null}
        {images.map(
          (
            picture: OfferImageType,
            index: string | number | null | undefined
          ) => (
            <OfferImage
              index={index}
              picture={picture}
              pictureIndex={pictureIndex}
              onClick={(image) => setImagesAsModal(image)}
              key={index}
            />
          )
        )}
        {images.length === 0 ? (
          <img
            src={`${process.env.PUBLIC_URL}/offers-images/apartment.jpeg`}
            alt="apartment"
            className="object-cover h-full w-full rounded-md"
            // onClick={() => console.log('fff')}
          />
        ) : null}
        {images.length > 1 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() =>
              setPictureIndex(
                pictureIndex === images.length - 1 ? 0 : pictureIndex + 1
              )
            }
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        ) : null}
      </div>
      <div className="flex flex-col flex-grow p-2">
        <div className="font-bold text-lg">{offer.propertyTypeName}</div>
        <div className="font-bold text-gravity-600 text-lg">
          {offer.areaName}: {offer.buildingName}
        </div>
        <div className="font-bold text-base">
          {t('common.from')}
          <b>
            {offer.rateAvailability.currencySymbol}
            {Number(offer.rateAvailability.priceFrom.gross).toFixed(2)}
          </b>{' '}
          {t('common.permonth')}
        </div>
        <div className="flex-grow font-apercu py-4 text-sm">
          {propertyTypeDescription(offer, selectedLanguage)}
        </div>
      </div>
      <OfferCardButton availableFrom={availableFrom} t={t} />
      <OfferCardLinkButton
        isAfter={reachedLimit}
        building={buildingName}
        callback={callback}
        offerId={offer.id}
        rateIdentifier={offer.rateAvailability.rateIdentifier}
        // @ts-ignore
        client={client}
        t={t}
      />
    </div>
  );
};

export default OfferCard;
