import {
  CalendlyEventListener,
  EventScheduledEvent,
  InlineWidget,
} from 'react-calendly';
import ReactDOM from 'react-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { createLead } from '../../../features/thunks';
import { ViewingType } from '../../../features/enquiry/enquirySlice';
import { OfferType } from '../../../service/types';
import { calendlies, neyborPaths } from '../../../service/utils';
import { getClientConfig } from '../../../config';
const { NODE_ENV } = process.env;

const CalendlyPage = () => {
  const dispatch = useAppDispatch();
  const fromVal = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    const { from } = enquiry;
    // @ts-ignore
    return new Date(from * 1000);
  });
  const durationVal = useAppSelector((state: RootState): string => {
    const { enquiry } = state;
    const { duration } = enquiry;
    // @ts-ignore
    return duration;
  });
  const viewingTypVal = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    const { viewingType } = enquiry;
    return viewingType;
  });

  const rootRl = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    const { rootEl } = enquiry;

    return rootEl;
  });

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const config = getClientConfig(client);
  const { availabilityStep } = config;

  const targetOffer = useAppSelector((state: RootState): string => {

    const { enquiry, offers, common } = state; // eslint-disable-line
    const { offerId, rateCode, client, viewingType } = enquiry;
    const currentOffers = offers.currentOffers;
    const terra = currentOffers?.find((toff: OfferType) => {
      return (
        toff.id === offerId && toff.rateAvailability.rateIdentifier === rateCode
      );
    });

    if (terra) {
      // @ts-ignore
      const { buildingName } = terra;

      if (buildingName && client) {

        const configuration = getClientConfig(client);
        const { calendlyLinkFilter } = configuration;

        if (calendlyLinkFilter) {
          const language = common.selectedLanguage ?? 'en';
          return calendlyLinkFilter(buildingName, language);
        } else {
          let url =
            calendlies[client][buildingName.toLowerCase()] ||
            calendlies[client]['default'];
          const path =
            client === 'neybor' ? neyborPaths[viewingType as ViewingType] : '';

          if (NODE_ENV === "development") {
            url = calendlies.default.default;
          }

          if (url) {
            return url + path;
          }
        }

      }
    }

    return '';
  });

  const buildingName = useAppSelector((state: RootState): any => {
    /*if (node_env === 'development') {
      return 'https://calendly.com/dgalitsyn/30min';
    }*/
    const { enquiry, offers } = state; // eslint-disable-line
    const { offerId, rateCode } = enquiry;
    const currentOffers = offers.offers;

    const terra = currentOffers.find((toff: any) => {
      return (
        toff.id === offerId && toff.rateAvailability.rateIdentifier === rateCode
      );
    });
    if (terra) {
      // @ts-ignore
      const { buildingName } = terra;
      if (buildingName) {
        return buildingName.toLowerCase();
      }
    }

    return null;
  });

  const hide = () => {
    if (rootRl !== null) {
      setTimeout(() => {
        const element = document.getElementById(rootRl);
        if (element) {
          // @ts-ignore
          ReactDOM.unmountComponentAtNode(element);
        }
      }, 3000);
    }
  };

  const eventScheduledCallback = async (event: EventScheduledEvent) => {
    if (event?.data?.event === 'calendly.event_scheduled') {
      await dispatch(createLead(event.data));
    }
    hide();
  };

  const decodeDuration = (dura: string) => {
    switch (dura) {
      case '6-9 months': {
        return '3';
      }
      case '10-12 months': {
        return '4';
      }
      case '3-5 months': {
        return '2';
      }
      default: {
        return '2';
      }
    }
  };

  const calculateCustomAnswers = () => {
    let ca: {} | null = null;
    if (
      buildingName !== null &&
      durationVal !== null &&
      fromVal !== null &&
      viewingTypVal !== null
    ) {
      if (buildingName.includes('west')) {
        ca = {
          a3: ['In person', 'Call'].includes(viewingTypVal) ? '1' : '2',
          a5: decodeDuration(durationVal),
        };
      } else if (buildingName.includes('royal')) {
        ca = {
          a2: fromVal.toLocaleString() ?? '',
          a3: ['In person', 'Call'].includes(viewingTypVal) ? '1' : '2',
        };
      } else {
        ca = {
          a2: ['In person', 'Call'].includes(viewingTypVal) ? '1' : '2',
          a3: fromVal.toLocaleString() ?? '',
          a4: decodeDuration(durationVal),
        };
      }
      return ca;
    } else {
      return null;
    }
  };

  if ((!targetOffer || calculateCustomAnswers() === null) && availabilityStep) {
    return null;
  }

  return (
    <CalendlyEventListener onEventScheduled={eventScheduledCallback}>
      <InlineWidget
        prefill={{
          // @ts-ignore
          customAnswers: calculateCustomAnswers(),
        }}
        styles={{ width: '100%' }}
        url={targetOffer}
      />
    </CalendlyEventListener>
  );
};

export default CalendlyPage;
