import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useStep } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  getAllDestinations,
  setDestination,
} from '../../../features/where/whereSlice';
import { AreaType } from '../../../service/types';

import AppLayout from '../../ui/AppLayout';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import Spinner from '../../ui/Spinner';
import {useTranslation} from "react-i18next";

const WherePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep = useStep('/');

  const destinations = useAppSelector((state: RootState) => {
    const { where } = state;
    return where.destinations;
  });
  const destinationsLoading = useAppSelector((state: RootState) => {
    const { where } = state;
    return where.loadingAllDestination;
  });

  const cl = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const { t } = useTranslation(cl as string);

  useEffect(() => {
    dispatch(getAllDestinations(cl));
  }, [dispatch, cl]);

  const selectDestination = (destination: AreaType) => {
    dispatch(setDestination(destination));
    navigate('/when');
  };

  return (
    <AppLayout image="where.jpg" step={currentStep}>
      <Heading className="second-font mb-10">{t('pages.Where.heading')}</Heading>
      <div className="flex flex-col items-center justify-center w-full">
        {destinationsLoading ? (
          <Spinner />
        ) : destinations === null || destinations.length < 1 ? (
          <div>
            {t('pages.Where.noDestination')}
          </div>
        ) : (
          destinations.map((dest: AreaType, index: number) => (
            <Button
              className="mb-8 last:mb-0"
              key={index}
              onClick={() => {
                selectDestination(dest);
              }}
            >
              {dest.area}
            </Button>
          ))
        )}
      </div>
    </AppLayout>
  );
};

export default WherePage;
