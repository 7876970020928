import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { getClient } from "../../service/api";
import { RESET_ALL, SET_OFFERS } from "../actions";
import { OfferType } from "../../service/types";

export interface OffersState {
  offers: OfferType[];
  offersLoading: string[];
  currentOffers: OfferType[] | null;
}

const initialState: OffersState = {
  offers: [],
  offersLoading: [],
  currentOffers: null,
};

export const getOffers = createAsyncThunk(
  "offers/get",
  async (config: any, { getState }) => {
    const state = getState();
    try {
      // @ts-ignore
      const { enquiry } = state;
      const { from, duration, guests, withPets, client } = enquiry;
      // const { chosenDestination } = where;
      // const areaId = chosenDestination?.identifier;
      const startDate = new Date(from * 1000);
      let endDate = new Date(startDate.getTime());

      const { longStayBookingTypeIdentifier, howLongMonths, availabilityFilter } = config;
      const bookingTypeIdentifier = longStayBookingTypeIdentifier ?? "";

      const howLongMonthsArray = Object.values(howLongMonths);
      const matchedHowLongMonthsObject: any = howLongMonthsArray.find(
        (element: any, index: number ) => {
          return index === duration;
        }
      );
      endDate.setMonth(endDate.getMonth() + matchedHowLongMonthsObject.months);

      const guestsCount = guests ? guests : 1;
      const params = {
        guests: guestsCount,
        page: 1,
        pageSize: 999,
        dateFrom: format(startDate, "yyyy-MM-dd"),
        dateTo: format(endDate, "yyyy-MM-dd"),
        grouping: "UNIT_TYPE_AND_RATE",
        bookingType: bookingTypeIdentifier,
      };
      if (withPets) {
        // @ts-ignore
        params.featureIds = "3d85704d-170a-11eb-a389-02a2eeb5fd9c";
      }
      const apiClient = getClient(client);
      const res = await apiClient.get("availabilities", {
        data: null,
        params,
      });
      const { data } = res;
      if (data.results !== undefined) {
        if (availabilityFilter) {
          return availabilityFilter(data.results);
        }
        return data.results;
      }
      throw new Error("No availability returned");
    } catch (e) {
      console.log({e});
      return [];
    }
  }
);

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setCurrentOffers: (
      state: OffersState,
      action: PayloadAction<OfferType[]>
    ) => {
      state.currentOffers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state) => {
      state.offersLoading.push("offers");
    });
    builder.addCase(getOffers.fulfilled, (state, action) => {
      // eslint-disable-line
      const { payload } = action;
      state.offersLoading = state.offersLoading.filter((i) => i !== "offers");
      state.offers = payload;
    });
    builder.addCase(getOffers.rejected, (state) => {
      state.offersLoading = state.offersLoading.filter((i) => i !== "offers");
      state.offers = [];
    });
    builder.addCase(RESET_ALL, () => {
      return { ...initialState };
    });
    builder.addCase(SET_OFFERS, (state, action) => {
      // @ts-ignore
      const { payload } = action;
      // @ts-ignore
      state.offers = payload;
      state.currentOffers = payload;
    });
  },
});

export const { setCurrentOffers } = offersSlice.actions;

export default offersSlice.reducer;
