import axios from "axios";

const baseUrl = {
  prod: "https://api.rerumapp.uk/api/v1/",
  development: "https://api-test.rerumapp.uk/api/v1/",
};

const { NODE_ENV, REACT_APP_CONNECTION_OVERRIDE } = process.env;

const tokens = {
  prod: {
    gravity:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJncmF2aXR5Y29saXZpbmctYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2NDg1NDU2Mzd9.bockss4q2qehqxgNKfwRVXFiQhzOfyvuSWMVqNtXH-o",
    //"neybor": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtdWx0aWN1cnJlbmN5dGVzdDMtYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.m5qqHtXE3b5uYUmeVuOupTfVyjaRyxm2P3Nixl78QLc",
    neybor:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuZXlib3ItYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.bFqt98Zkqs6I1PhKTPsWmTYn7LvhGW0vPx_4ogC4m1M",
    vitanovae: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXRhbm92YWUtYXBpIiwiZXhwIjoyMDk4NTE3Mjk3LCJpYXQiOjE3MTk4MjU4MTN9.NTnkhRxAKaC8ImVDPJySg9zRU3b7-9Doez4zN2fQmZM",
  },
  development: {
    multicurrencytest3:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtdWx0aWN1cnJlbmN5dGVzdDMtYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.m5qqHtXE3b5uYUmeVuOupTfVyjaRyxm2P3Nixl78QLc",
    mcdemo:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtY2RlbW8tYXBpIiwiZXhwIjoyMDg3NzI3ODQ1LCJpYXQiOjE3MDkwMzY2Mjl9.SkhOzHx6yI0KXrUd-JrZg0Tf2xEEIW2ESASf1RMrJZs",
  },
};

const getToken = (env: string, client: string) => {
  if (REACT_APP_CONNECTION_OVERRIDE != undefined) {
    // @ts-ignore
    return tokens.development[REACT_APP_CONNECTION_OVERRIDE] ? tokens.development[REACT_APP_CONNECTION_OVERRIDE] : tokens.development.multicurrencytest3;
  } else {
    if (env === "development" || client === null) {
      return tokens.development.multicurrencytest3;
    }
    // @ts-ignore
    return tokens.prod[client] ? tokens.prod[client] : tokens.development.multicurrencytest3;
  }
};

const getBaseUrl = (env: string) => {
  return env === "development" ? baseUrl.development : baseUrl.prod;
};

const getClient = (client: string | null) => {
  // @ts-ignore
  const token = getToken(NODE_ENV, client);
  return axios.create({
    baseURL: getBaseUrl(NODE_ENV),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // @ts-ignore
      Authorization: token,
    },
  });
};

export { getClient };
