export default () => {
  return {
    pages: {
      Budget: {
        heading: 'What’s your monthly budget?',
        name: 'Budget?',
        blurb: 'Our monthly rates include utility bills!',
      },
      Calendly: {

      },
      Done: {
        heading: 'Done',
        name: 'Book a viewing!',
        allSet: 'You are all set',
      },
      HowLong: {
        heading: 'How long are you staying?',
        name: 'When?',
        months: {
          0: '3-5 months',
          1: '6-9 months',
          2: '10-12 months',
        }
      },
      Offers: {
        heading: 'This is what we have available for you...',
        name: 'Units',
        messages: {
          nothingFoundHeading: 'It looks like we cannot offer you anything right now...',
          helpBlurb: 'Please contact our sales team via the chat button',
          startAgainButton: 'Start Again?',
        },
        available: {
          helpBlurb: 'If you don\'t see what are you looking for below, please contact our sales team via the chat button',
          nothingToOfferAtDestination: 'It looks like we cannot offer you anything in {{name}} right now',
        }
      },
      Viewing: {
        heading: 'Meet us',
        name: 'Book a viewing!',
        types: {
          inperson: 'In person',
          video: 'Video Call',
          call: 'Call',
        },
      },
      When: {
        heading: 'When would you like to move?',
        name: 'When?',
      },
      Where: {
        heading: 'Come to live in',
        name: 'Where?',
        noDestination: 'No destinations',
      },
      Who: {
        heading: "Who's moving in?",
        name: 'Who?',
        buttons: {
          justme: 'Just me',
          meandpartner: 'Me & my partner',
          meandmyfriend: 'Me & my friend',
          morethantwoofus: 'More than 2 of us',
        },
        pets: {
          heading: 'Do you have any pets?',
          buttons: {
            yes: 'Yes',
            no: 'No',
          },
          blurb: 'Pets considered on application',
        }
      },
    },
    common: {
      buttons: {
        close: 'Close',
        bookaviewing: 'Book a viewing!',
        registerinterest: 'Register your interest!',

      },
      from: 'from ',
      permonth: 'per month',
      availability: {
        from: 'Available from {{from}}',
        now: 'Available now',
      },

    }
  };
};