import { FC } from 'react';
import type {TFunction} from "i18next";

interface OfferCardButtonProps {
  availableFrom: string | null;
  t: TFunction;
}

const OfferCardButton: FC<OfferCardButtonProps> = ({ availableFrom, t }) => {
  const text = availableFrom ? t('common.availability.from', { from: availableFrom }) : t('common.availability.now');
  return (
    <div className="mt-6 text-gray-500 text-sm text-right">
      {text}
    </div>
  );
};

export default OfferCardButton;
