export default () => {
  return {
    pages: {
      Budget: {
        heading: 'Quel est votre budget mensuel ?',
        name: 'Budget ?',
        blurb: 'Nos tarifs mensuels incluent les factures de services publics !',
      },
      Calendly: {

      },
      Done: {
        heading: 'Terminé',
        name: 'Réservez une visite !',
        allSet: 'Tout est prêt',
      },
      HowLong: {
        heading: 'Combien de temps restez-vous ?',
        name: 'Quand ?',
        months: {
          0: '3-5 mois',
          1: '6-9 mois',
          2: '10-12 mois',
        }
      },
      Offers: {
        heading: 'Voici ce que nous avons à vous proposer...',
        name: 'Unités',
        messages: {
          nothingFoundHeading: 'Il semble que nous ne puissions rien vous offrir pour le moment...',
          helpBlurb: 'Veuillez contacter notre équipe de vente via le bouton de chat',
          startAgainButton: 'Recommencer ?',
        },
        available: {
          helpBlurb: 'Si vous ne trouvez pas ce que vous cherchez ci-dessous, veuillez contacter notre équipe de vente via le bouton de chat',
          nothingToOfferAtDestination: 'Il semble que nous ne puissions rien vous offrir à {{name}} pour le moment',
        }
      },
      Viewing: {
        heading: 'Rencontrez-nous',
        name: 'Réservez une visite !',
        types: {
          inperson: 'En personne',
          video: 'Appel vidéo',
          call: 'Appel',
        },
      },
      When: {
        heading: 'Quand souhaitez-vous emménager ?',
        name: 'Quand ?',
      },
      Where: {
        heading: 'Venir vivre à',
        name: 'Où ?',
        noDestination: 'Aucune destination',
      },
      Who: {
        heading: 'Qui emménage ?',
        name: 'Qui ?',
        buttons: {
          justme: 'Juste moi',
          meandpartner: 'Moi & mon partenaire',
          meandmyfriend: 'Moi & mon ami',
          morethantwoofus: 'Plus de 2 personnes',
        },
        pets: {
          heading: 'Avez-vous des animaux de compagnie ?',
          buttons: {
            yes: 'Oui',
            no: 'Non',
          },
          blurb: 'Les animaux de compagnie sont pris en compte sur demande',
        }
      },
    },
    common: {
      buttons: {
        close: 'Fermer',
        bookaviewing: 'Réservez une visite !',
        registerinterest: 'Inscrivez-vous !',
      },
      from: 'à partir de ',
      permonth: 'par mois',
      availability: {
        from: 'Disponible à partir de {{from}}',
        now: 'Disponible maintenant',
      },
    }
  };
};
