import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useStep } from '../../../app/hooks';
import { setDuration } from '../../../features/enquiry/enquirySlice';

import AppLayout from '../../ui/AppLayout';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import { RootState } from '../../../app/store';
import { getClientConfig } from '../../../config';
import { useTranslation } from 'react-i18next';

const HowLongPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep = useStep('/how-long');

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const { t } = useTranslation(client as string);

  const config = getClientConfig(client);

  let durations = [0, 1, 2];

  const { howLongDurations } = config;

  if (howLongDurations) {
    durations = howLongDurations;
  }

  const handleChangeDuration = (from: any) => {
    dispatch(setDuration(from));
    navigate('/who');
  };

  return (
    <AppLayout image="how-long.jpg" step={currentStep}>
      <Heading className="second-font mb-10">
        {t('pages.HowLong.heading')}
      </Heading>
      <div className="flex flex-col items-center w-full">
        {durations.map((duration, index) => (
          <Button
            className="mb-8 last:mb-0"
            key={index}
            onClick={() =>
              handleChangeDuration(duration)
            }
          >
            {t(`pages.HowLong.months.${duration}.text`)}
          </Button>
        ))}
      </div>
    </AppLayout>
  );
};

export default HowLongPage;
