import { FC, ChangeEvent, useState, useEffect, useRef } from 'react';

import { BudgetType } from '../../../features/enquiry/enquirySlice';

interface RangeSliderProps {
  initialMin: number;
  initialMax: number;
  min: number;
  max: number;
  step: number;
  onChange: (budget: BudgetType) => void; // eslint-disable-line
}

const RangeSlider: FC<RangeSliderProps> = ({
  initialMin,
  initialMax,
  min,
  max,
  step,
  onChange,
}) => {
  const progressRef = useRef<HTMLInputElement | null>(null);
  const [minValue, setMinValue] = useState(initialMin);
  const [maxValue, setMaxValue] = useState(initialMax);

  const handleMin = (e: ChangeEvent<HTMLInputElement>) => {
    const _minValue = parseInt(e.target.value);

    if (_minValue < maxValue) {
      setMinValue(_minValue);
      onChange({ min: _minValue, max: maxValue });
    }
  };

  const handleMax = (e: ChangeEvent<HTMLInputElement>) => {
    const _maxValue = parseInt(e.target.value);

    if (_maxValue > minValue) {
      setMaxValue(_maxValue);
      onChange({ min: minValue, max: _maxValue });
    }
  };

  useEffect(() => {
    if (progressRef.current !== null) {
      const _max = max - min;
      progressRef.current.style.left = ((minValue - min) / _max) * 100 + '%';
      progressRef.current.style.right =
        (((maxValue - min) / _max) * 100 - 100) * -1 + '%';
    }
  }, [minValue, maxValue, max, step]); // eslint-disable-line

  return (
    <div className="w-full">
      <div className="slider relative h-1 rounded-md bg-gravity">
        <div
          className="progress absolute h-1 bg-gravity-400 rounded"
          ref={progressRef}
        ></div>
        <div className="range-input relative">
          <input
            onChange={handleMin}
            type="range"
            min={min}
            step={step}
            max={max}
            value={minValue}
            className="range-min absolute w-full h-1 bg-transparent  appearance-none pointer-events-none"
          />
          <input
            onChange={handleMax}
            type="range"
            min={min}
            step={step}
            max={max}
            value={maxValue}
            className="range-max absolute w-full h-1 bg-transparent appearance-none  pointer-events-none"
          />
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
