import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import enquiryReducer from "../features/enquiry/enquirySlice";
import whereReducer from "../features/where/whereSlice";
import offersReducer from "../features/offers/offersSlice";
import commonReducer from "../features/common/commonSlice";

const reducer = combineReducers({
  enquiry: enquiryReducer,
  where: whereReducer,
  offers: offersReducer,
  common: commonReducer,
});

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
