import {createSlice} from "@reduxjs/toolkit";
import {RESET_ALL, SET_SELECTED_LANGUAGE} from "../actions";

export interface Translation {
	name: string;
	resource: {};
	flagIcon: string;
	lang: string;
}
export interface CommonSliceState {
	defaultLanguage: string;
	availableLanguages: Translation[];
	selectedLanguage: string;
}

const initialState: CommonSliceState = {
	defaultLanguage: '',
	availableLanguages: [],
	selectedLanguage: '',
};

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setDefaultLanguage: (state, action) => {
			state.defaultLanguage = action.payload;
		},
		setAvailableLanguages: (state, action) => {
			state.availableLanguages = action.payload;
		},
		setSelectedLanguage: (state, action) => {
			state.selectedLanguage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(SET_SELECTED_LANGUAGE, (state, action: any) => {
				return {
					...state,
					selectedLanguage: action.payload,
				};
			})
			.addCase(RESET_ALL, () => {
				return {
					...initialState,
				};
			});
	}
});

export const {setDefaultLanguage, setAvailableLanguages, setSelectedLanguage} = commonSlice.actions;

export default commonSlice.reducer;
