import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useStep } from '../../../app/hooks';
import {
  setViewingType,
} from '../../../features/enquiry/enquirySlice';

import AppLayout from '../../ui/AppLayout';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import { RootState } from '../../../app/store';
import { getClientConfig } from '../../../config';
import { setManualOffers } from '../../../features/actions';
import {useTranslation} from "react-i18next";

const ViewingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useStep('/viewing');

  let types: string[] = ['pages.Viewing.types.video', 'pages.Viewing.types.inperson'];

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const { t } = useTranslation(client as string);

  const config = getClientConfig(client);
  const { availabilityStep, unit, rateId, offerId, viewingOptions } = config;

  if (viewingOptions) {
    types = viewingOptions;
  }

  const handleChangeViewingType = (viewingType: string) => {
    dispatch(setViewingType(viewingType));

    if (!availabilityStep) {
      const offer = {
        id: offerId,
        rateAvailability: {
          rateIdentifier: rateId,
        },
        buildingName: 'default',
        name: unit,
      };
      dispatch(setManualOffers([offer]));
    }
    navigate('/calendly');
  };

  return (
    <AppLayout image="viewing.jpg" step={currentStep}>
      <Heading className="second-font mb-10">{t('pages.Viewing.heading')}</Heading>
      <div className="flex flex-col items-center w-full">
        {types.map((type, index) => (
          <Button
            className="mb-8 last:mb-0"
            key={index}
            onClick={() => {
              handleChangeViewingType(t(type));
            }}
          >
            {t(type)}
          </Button>
        ))}
      </div>
    </AppLayout>
  );
};

export default ViewingPage;
