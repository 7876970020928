import cn from 'classnames';
import { FC } from 'react';

interface ButtonProps {
  isActive?: boolean;
  onClick: () => void;
  className?: string;
}

const Button: FC<ButtonProps> = ({
  isActive,
  onClick,
  className = '',
  children,
}) => (
  <button
    className={cn(
      'p-4 rounded-full text-button w-full md:w-80',
      'active:bg-gravity/80 hover:bg-gravity/80 focus:outline-none',
      className,
      {
        'bg-gravity/80': isActive,
        'bg-gravity': !isActive,
      }
    )}
    type="button"
    tabIndex={0}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
