import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { getClientConfig } from '../../../config';
import { StepType } from '../../../config/types';
import {useTranslation} from "react-i18next";

interface StepsProps {
  stepIndex?: number;
  className?: string;
}

type ProgresStepType = {
  index: number;
  name: string;
  route: string;
  internalName: string;
};

const Steps: FC<StepsProps> = ({ stepIndex, className = '' }) => {
  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });
  const { t } = useTranslation(client as string);
  const config = getClientConfig(client);
  const steps = config.steps as StepType[];

  const mappedSteps = steps.reduce<ProgresStepType[]>((arr, step) => {
    if (arr.find((item) => item.index === step.stepIndex)) {
      return arr;
    }

    return [
      ...arr,
      {
        internalName: step.internalName,
        name: step.name,
        index: step.stepIndex,
        route: step.route,
      },
    ];
  }, []);

  const currentStep = stepIndex ?? mappedSteps.length - 1;

  return (
    <div
      className={cn(
        'flex flex-none justify-between py-6 relative w-full',
        className
      )}
    >
      {mappedSteps.map((step) => (
        <div key={step.index} className="flex flex-col flex-1 items-center">
          <div className="flex justify-center items-center h-4 w-full">
            <div
              className={cn('h-0.5 w-full', {
                'bg-gravity-200': step.index > 0 && currentStep < step.index,
                'bg-gravity-400': step.index > 0 && currentStep >= step.index,
              })}
            />
            <div
              className={cn('flex-none h-full w-0.5', {
                'bg-gravity-200': step.index > currentStep,
                'bg-gravity-400': step.index <= currentStep,
              })}
            />
            <div
              className={cn('h-0.5 w-full', {
                'bg-gravity-200':
                  step.index < mappedSteps.length - 1 &&
                  currentStep <= step.index,
                'bg-gravity-400':
                  step.index < mappedSteps.length - 1 &&
                  currentStep > step.index,
              })}
            />
          </div>
          {step.index <= currentStep && (
            <Link
              className={cn({
                'font-bold': step.index === currentStep,
              })}
              to={step.route}
            >
              {t(`pages.${step.internalName}.name`)}
            </Link>
          )}
          {step.index > currentStep && <div>{t(`pages.${step.internalName}.name`)}</div>}
        </div>
      ))}
    </div>
  );
};

export default Steps;
