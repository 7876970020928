import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useStep } from '../../../app/hooks';
import {
  setBudget,
  setOfferId,
  setRateCode,
} from '../../../features/enquiry/enquirySlice';

import AppLayout from '../../ui/AppLayout';
import Button from '../../ui/Button';
import RangeSlider from '../../ui/RangeSlider';
import Heading from '../../ui/Heading';
import { RootState } from '../../../app/store';
import { getClientConfig } from '../../../config';
import { useTranslation } from 'react-i18next';

const BudgetPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep = useStep('/budget');

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const { t } = useTranslation(client as string);

  const config = getClientConfig(client);

  let initialMinDelta = 2000;
  let initialMaxDelta = 3000;
  let currency = '£';

  const { availabilityStep, rateId, offerId, budgetScale, currencySymbol } =
    config;
  const { min, max, step, initialMin, initialMax } = budgetScale;

  if (initialMin != undefined && initialMax != undefined) {
    initialMinDelta = initialMin;
    initialMaxDelta = initialMax;
  }

  if (currencySymbol) {
    currency = currencySymbol;
  }

  const [budget, setBudgetValue] = useState({
    min: initialMinDelta,
    max: initialMaxDelta,
  });

  let minDelta = 1000;
  let maxDelta = 5000;
  let stepDelta = 200;

  if (min != undefined && max != undefined && step != undefined) {
    minDelta = min;
    maxDelta = max;
    stepDelta = step;
  }

  const budgetMin = `${currency}${budget.min}`;
  const budgetMax = `${currency}${budget.max}`;

  const handleChangeBudget = () => {
    dispatch(setBudget(budget));
    let next = '/offers';
    if (!availabilityStep) {
      dispatch(setOfferId(offerId));
      dispatch(setRateCode(rateId));
      next = '/viewing';
    }
    navigate(next);
  };

  return (
    <AppLayout image="budget.jpg" step={currentStep}>
      <Heading className="second-font mb-10">
        {t('pages.Budget.heading')}
      </Heading>
      <div className="flex flex-col items-center w-full">
        <RangeSlider
          initialMin={budget.min}
          initialMax={budget.max}
          min={minDelta}
          max={maxDelta}
          step={stepDelta}
          onChange={(budget) => setBudgetValue(budget)}
        />
        <Button
          className="my-10"
          onClick={() => {
            handleChangeBudget();
          }}
        >
          {budgetMin} - {budgetMax}
        </Button>
      </div>
      <div className="text-center">{t('pages.Budget.blurb')}</div>
    </AppLayout>
  );
};

export default BudgetPage;
