import { useNavigate } from 'react-router-dom';
import { useEffect, FC, useState, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { resetAll } from '../../../features/actions';
import {
  setOfferId,
  setRateCode,
} from '../../../features/enquiry/enquirySlice';
import {
  getOffers,
  setCurrentOffers,
} from '../../../features/offers/offersSlice';
import { setDestination } from '../../../features/where/whereSlice';
import { AreaType, OfferType } from '../../../service/types';
import {
  chunk,
  distanceInKmBetweenEarthCoordinates,
} from '../../../service/utils';

import { getClientConfig } from '../../../config';

import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import OfferLine from '../../ui/OfferLine';
import OffersLayout from '../../ui/OffersLayout';
import { RootState } from '../../../app/store';
import { useTranslation } from 'react-i18next';

const OffersPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loadedDestinations, setLoadedDestinations] = useState<number[]>([]);
  const [offerLines, setOfferLines] = useState<OfferType[][]>([]);
  const [nextDestination, setNextDestination] = useState<AreaType | null>(null);
  const [firstDestination, setFirstDestination] = useState<AreaType | null>(
    null
  );

  const offersLoading = useAppSelector((state) => {
    const { offers } = state;
    return offers.offersLoading.length;
  });
  const availableOffers = useAppSelector((state) => {
    const { offers } = state;
    return offers.offers;
  });
  const currentOffers = useAppSelector((state) => {
    const { offers } = state;
    return offers.currentOffers;
  });
  const destinations = useAppSelector((state) => {
    const { where } = state;
    return where.destinations;
  });
  const chosenDestination = useAppSelector((state) => {
    const { where } = state;
    return where.chosenDestination;
  });
  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });
  const { t } = useTranslation(client as string);

  const config = getClientConfig(client);

  useEffect(() => {
    dispatch(getOffers(config));
  }, [dispatch, config]);

  useEffect(() => {
    if (chosenDestination?.identifier) {
      setLoadedDestinations([
        ...loadedDestinations,
        chosenDestination.identifier,
      ]);
    }
  }, [chosenDestination]); // eslint-disable-line

  useEffect(() => {
    if (!nextDestination) {
      setFirstDestination(chosenDestination);
    }
  }, [chosenDestination, nextDestination]);

  useEffect(() => {
    if (chosenDestination?.identifier && availableOffers.length > 0) {
      const offers = availableOffers.filter(
        (item: OfferType) => item.areaId === chosenDestination.identifier
      );

      setOfferLines(chunk(offers, 3));
      dispatch(setCurrentOffers(offers));
    }
  }, [chosenDestination, availableOffers, dispatch]);

  const filteredDestionations = useMemo(() => {
    if (loadedDestinations.length > 0) {
      return destinations.filter(
        (item: AreaType) => !loadedDestinations.includes(item.identifier)
      );
    }

    return [];
  }, [destinations, loadedDestinations]);

  useEffect(() => {
    if (
      chosenDestination?.identifier &&
      filteredDestionations.length > 0 &&
      currentOffers?.length === 0
    ) {
      const destinations = filteredDestionations.sort(
        (a: AreaType, b: AreaType) => {
          const destination1 = distanceInKmBetweenEarthCoordinates(
            { lat: a.latitude || 0, lon: a.longitude || 0 },
            {
              lat: chosenDestination.latitude || 0,
              lon: chosenDestination.longitude || 0,
            }
          );

          const destination2 = distanceInKmBetweenEarthCoordinates(
            {
              lat: chosenDestination.latitude || 0,
              lon: chosenDestination.longitude || 0,
            },
            { lat: b.latitude || 0, lon: b.longitude || 0 }
          );

          if (destination1 < destination2) {
            return -1;
          }

          return 1;
        }
      );

      const nextDestination = destinations[0];

      if (nextDestination) {
        setNextDestination(nextDestination);
        dispatch(setDestination(nextDestination));
      }
    }
  }, [currentOffers, chosenDestination, filteredDestionations, dispatch]);

  const handleChangeOffer = (offerId: string | number, rateId: string) => {
    dispatch(setOfferId(offerId));
    dispatch(setRateCode(rateId));
    navigate('/viewing');
  };

  const reset = () => {
    dispatch(resetAll());
  };

  if (offerLines && offerLines?.length < 1) {
    return (
      <OffersLayout isLoading={offersLoading > 0}>
        <Heading className="second-font mb-8">
          {t('pages.Offers.messages.nothingFoundHeading')}
        </Heading>
        <div className="mb-6">{t('pages.Offers.messages.helpBlurb')}</div>
        <div>
          <Button onClick={reset}>
            {t('pages.Offers.messages.startAgainButton')}
          </Button>
        </div>
      </OffersLayout>
    );
  }

  if (offerLines && offerLines?.length > 0) {
    return (
      <OffersLayout isLoading={offersLoading > 0}>
        <Heading className="second-font mb-8">
          {t('pages.Offers.heading')}
        </Heading>
        <div className="text-center mb-6">
          {t('pages.Offers.available.helpBlurb')}
          {/*If you don't see what are you looking for below, please contact our
          sales team via the chat button*/}
        </div>
        {nextDestination && (
          <>
            <div className="text-center mb-6">
              {t('pages.Offers.available.nothingToOfferAtDestination', {
                name: firstDestination?.area,
              })}
            </div>
            <div className="text-center font-bold text-xl mb-6">
              {nextDestination.area}
            </div>
          </>
        )}
        <div className="flex flex-col">
          {offerLines.map((offerArray: object[], index: number) => (
            <OfferLine
              key={index}
              offers={offerArray}
              onChoose={handleChangeOffer}
            />
          ))}
        </div>
      </OffersLayout>
    );
  }

  return null;
};

export default OffersPage;
