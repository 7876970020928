import en from './locales/en';

const steps = [
  {
    name: 'Where?',
    internalName: 'Where',
    route: '/',
    title: 'In which corner of Brussels would you like to live?',
    stepIndex: 0,
  },
  {
    name: 'When?',
    internalName: 'When',
    route: '/when',
    title: 'When would you like to move in?',
    stepIndex: 1,
    reverseLayout: true,
  },
  {
    name: 'When?',
    internalName: 'HowLong',
    route: '/how-long',
    title: 'How long do you plan to stay with us?',
    stepIndex: 1,
  },
  {
    name: 'Who?',
    internalName: 'Who',
    route: '/who',
    title: "Who's moving in?",
    stepIndex: 2,
    reverseLayout: true,
    contentClassName: 'flex-col items-center',
  },
  {
    name: 'Budget?',
    internalName: 'Budget',
    route: '/budget',
    title: 'What is your monthly budget?',
    stepIndex: 3,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Viewing',
    route: '/viewing',
    title: 'How would you like to meet us?',
    stepIndex: 4,
    reverseLayout: true,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Done',
    route: '/done',
    title: 'Done',
    stepIndex: 4,
  },
];

const namespace = 'neybor';

// override the translations from default
const english = en();

english.pages.HowLong.months = {
  0: { text: '3 months', months: 3 },
  1: { text: '4 months', months: 4 },
  2: { text: '5 months', months: 5 },
  3: { text: '6+ months', months: 6 },
};

const howLongMonths = english.pages.HowLong.months;

english.pages.Budget.blurb =
  'OUR PRICES INCLUDE FURNITURE, UTILITY BILLS, CLEANING, COFFEE GRAINS, SOAPS, DETERGENT AND MORE!';
english.pages.Where.heading =
  'In which corner of Brussels would you like to live?';
english.pages.When.heading = 'When would you like to move in?';
english.pages.HowLong.heading = 'How long do you plan to stay with us?';
english.pages.Who.heading = "Who's moving in?";
english.pages.Budget.heading = 'What is your monthly budget?';
english.pages.Viewing.heading = 'How would you like to meet us?';

const translations = [
  { lang: 'en', resource: english, flagIcon: 'gb', name: 'English' },
];

const test = {
  steps,
  petSection: false,
  unit: 'ghost_unit',
  rateId: 1,
  offerId: '123',
  chessLayout: true,
  fullBackgroundImage: false,
  howLongDurations: [0, 1, 2, 3],
  budgetScale: {
    initialMin: 900,
    initialMax: 1500,
    min: 700,
    max: 2000,
    step: 100,
  },
  currencySymbol: '€',
  budgetBlurb:
    'OUR PRICES INCLUDE FURNITURE, UTILITY BILLS, CLEANING, COFFEE GRAINS, SOAPS, DETERGENT AND MORE!',
  viewingOptions: ['pages.Viewing.types.call', 'pages.Viewing.types.video'],
  namespace,
  translations,
  howLongMonths,
};

const prod = {
  steps,
  petSection: false,
  unit: 'ghost_unit',
  rateId: 1,
  offerId: '123',
  fullBackgroundImage: false,
  howLongDurations: [0, 1, 2, 3],
  budgetScale: {
    initialMin: 900,
    initialMax: 1500,
    min: 700,
    max: 2000,
    step: 100,
  },
  currencySymbol: '€',
  budgetBlurb:
    'OUR PRICES INCLUDE FURNITURE, UTILITY BILLS, CLEANING, COFFEE GRAINS, SOAPS, DETERGENT AND MORE!',
  viewingOptions: ['pages.Viewing.types.call', 'pages.Viewing.types.video'],
  namespace,
  translations,
  howLongMonths,
};

const deltas = {
  test,
  prod,
};
export default deltas;
