import en from './locales/en';

const steps = [
  {
    name: 'Where?',
    internalName: 'Where',
    route: '/',
    title: 'Where would you like to live?',
    stepIndex: 0,
  },
  {
    name: 'When?',
    internalName: 'When',
    route: '/when',
    title: 'When would you like to move?',
    stepIndex: 1,
  },
  {
    name: 'When?',
    internalName: 'HowLong',
    route: '/how-long',
    title: 'How long are you staying?',
    stepIndex: 1,
  },
  {
    name: 'Who?',
    internalName: 'Who',
    route: '/who',
    title: "Who's moving in?",
    stepIndex: 2,
  },
  {
    name: 'Budget?',
    internalName: 'Budget',
    route: '/budget',
    title: 'What’s your monthly budget?',
    stepIndex: 3,
  },
  {
    name: 'Units',
    internalName: 'Offers',
    route: '/offers',
    title: 'This is what we have available for you...',
    stepIndex: 4,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Viewing',
    route: '/viewing',
    title: 'How would you like to do the viewing?',
    stepIndex: 5,
  },
  {
    name: 'Book a viewing!',
    internalName: 'Done',
    route: '/done',
    title: 'Done',
    stepIndex: 5,
  },
];

const budgetScale = {
  initialMin: 2000,
  initialMax: 3000,
  min: 1000,
  max: 5000,
  step: 200,
};

const longStayBookingTypeIdentifier = 'c796896e-0b26-4db0-bd0a-1c3edf312d5a';

const availabilityStep = true;

const namespace = 'gravitycoliving';

const english = en();

const translations = [
  { lang: 'en', resource: english, flagIcon: 'gb', name: 'English' },
];

english.pages.HowLong.months = {
  0: { text: '3-5 months', months: 3 },
  1: { text: '6-9 months', months: 6 },
  2: { text: '10-12 months', months: 10 },
};

const howLongMonths = english.pages.HowLong.months;

const test = {
  petSection: true,
  steps,
  budgetScale,
  availabilityStep,
  namespace,
  translations,
  longStayBookingTypeIdentifier,
  howLongMonths,
};

const prod = {
  petSection: true,
  steps,
  budgetScale,
  availabilityStep,
  namespace,
  translations,
  longStayBookingTypeIdentifier,
  howLongMonths,
};

const deltas = {
  test,
  prod,
};

export default deltas;
